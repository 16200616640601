import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { getDistinctValues, getClaims } from "../../api/claimApi";
import { AUTH_ERROR } from "../../Constants/constants";
import {
  ChatHistory,
  ChatHistoryType,
  CombinedClaim,
  DailyInsight,
  Parameter,
  ScreenStateInterface,
  UIActionInterface,
  columnConfig,
  getTagColor,
} from "../../Constants/interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClaimModal from "../../components/ClaimModal";
import {
  LoadingBox,
  LoadingContainer,
  LoadingText,
  Spinner,
} from "../../components/Loading";
import { authFetch } from "../../Utils/authFetch";
import { isDemoTenant } from "../../Utils/tenantUtils";
import ChatBot from "../../components/ChatBot/ChatBot";

//  display: flex;

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  background: #f5f7fa;
  margin: 0 auto;
`;

interface MainContentProps {
  isSidebarOpen: boolean;
}

interface SidebarProps {
  isOpen: boolean;
}

interface ToggleSidebarButtonProps {
  isSidebarOpen: boolean;
}

const MainContent = styled.div<MainContentProps>`
  flex-grow: 1;
  margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? "350px" : "0")};
  transition: margin-left 0.3s ease-in-out;
`;

const Sidebar = styled.div<SidebarProps>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(${({ isOpen }) => (isOpen ? "0" : "-100%")});
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  padding: 20px;
  padding-right: 40px;
  z-index: 1000;
`;

// const ToggleSidebarButton = styled.button<ToggleSidebarButtonProps>`
//   position: fixed;
//   left: ${({ isSidebarOpen }) => (isSidebarOpen ? "320px" : "0")};
//   top: 50%;
//   transform: translateY(-50%);
//   background-color: #28a745;
//   color: white;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   transition: left 0.3s ease-in-out;
//   z-index: 1001;
// `;

const ToggleSidebarButton = styled.button<ToggleSidebarButtonProps>`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 14px;
  cursor: pointer;
  width: 60px;
  height: 100%;
`;
const TableWrapper = styled.div`
  background: white;
  border-radius: 8px;
  overflow-x: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
`;

const TableHeader = styled.thead`
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f1f3f5;
  }

  &:hover {
    background-color: #e9ecef;
  }
`;

const TableHeaderCell = styled.th`
  padding: 6px;
  padding-left: 12px;
  text-align: left;
  font-weight: bold;
  color: #495057;
  border-bottom: 2px solid #dee2e6;
  white-space: nowrap;
  width: 250px;
`;

const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
`;

const TruncatedCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ClearFiltersButton = styled(Button)`
  background-color: #e74c3c;

  &:hover {
    background-color: #c0392b;
  }
`;

const FilterModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FilterLabel = styled.label`
  font-weight: bold;
`;

const FilterInput = styled.input`
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const FilterSelect = styled.select`
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const ApplyButton = styled(Button)`
  background-color: #28a745;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 3px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const InsightsContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const InsightItem = styled.div`
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;

const ToggleButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #218838;
  }
`;

const TagButton = styled.button<{ $tagColor: string }>`
  background-color: ${(props) => props.$tagColor};
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 2px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    filter: brightness(90%);
  }
`;
const ActiveFilterIndicator = styled.div<{ $tagColor: string }>`
  background-color: ${(props) => props.$tagColor};
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClearFilterButton = styled.button`
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const CollapsibleContent = styled.div<{ isexpanded: boolean }>`
  max-height: ${(props) => (props.isexpanded ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

const Title = styled.h1`
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 100px;
`;
const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;
const SearchBarContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const DashboardPage: React.FC = () => {
  const [claims, setClaims] = useState<CombinedClaim[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedClaimId, setSelectedClaimId] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [globalError, setError] = useState<Error | null>(null);
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [distinctValues, setDistinctValues] = useState<
    Record<string, string[]>
  >({});
  const navigate = useNavigate();
  const [isInsightsExpanded, setIsInsightsExpanded] = useState(false);

  const sortBy = searchParams.get("sortBy") || "procedure_date";
  const sortOrder = searchParams.get("sortOrder") || "DESC";
  const page = parseInt(searchParams.get("page") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || "50");
  const [lastSearchParams, setLastStepParams] = useState<Object>({});
  const [dailyInsights, setDailyInsights] = useState<DailyInsight[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTagFilter, setActiveTagFilter] = useState<string | null>(null);
  const [claimModalChatScreenState, setClaimModalChatScreenState] =
    useState<ScreenStateInterface | null>(null);
  // useEffect(() => {
  //   const fetchDailyInsights = async () => {
  //     try {
  //       const data = await authFetch("/daily-insights");
  //       console.log("dailtInsightsData", data);
  //       if (Array.isArray(data)) {
  //         setDailyInsights(data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching daily insights:", error);
  //     }
  //   };
  //   if (dailyInsights.length === 0) {
  //     fetchDailyInsights();
  //   }
  // }, []);

  useEffect(() => {
    const fetchDistinctValues = async () => {
      try {
        const values = await getDistinctValues();
        setDistinctValues(values);
      } catch (error) {
        console.error("Error fetching distinct values:", error);
      }
    };

    if (Object.keys(distinctValues).length === 0) {
      fetchDistinctValues();
    }
  }, []);

  useEffect(() => {
    // Check for claim ID in URL when component mounts
    const claimIdFromUrl = searchParams.get("claim_h_uuid");
    if (claimIdFromUrl) {
      setSelectedClaimId(claimIdFromUrl);
    }
    setSearchTerm(searchParams.get("search") || "");
  }, [searchParams]);

  const fetchClaims = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let paramsToSearch = Object.fromEntries(searchParams);
      const { claims: claimsData, totalCount: total } = await getClaims(
        paramsToSearch
      );
      setLastStepParams(paramsToSearch);
      setClaims(claimsData);
      setTotalCount(total);
    } catch (err) {
      setError(err as Error);
      if ((err as any).message === AUTH_ERROR) {
        navigate("/login");
      }
    } finally {
      setIsLoading(false);
    }
  };

  let seachDebounceTimer: any = null;
  useEffect(() => {
    // we need to make sure that the searchParams have changed - but not due to claim_h_uuid
    let paramsToSearch = Object.fromEntries(searchParams);
    delete paramsToSearch.claim_h_uuid;
    if (
      claims.length > 0 &&
      JSON.stringify(paramsToSearch) === JSON.stringify(lastSearchParams)
    ) {
      return;
    }
    seachDebounceTimer = setTimeout(() => {
      fetchClaims();
    }, 500); // Debounce for 300ms
    return () => clearTimeout(seachDebounceTimer);
  }, [navigate, searchParams]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    clearTimeout(seachDebounceTimer);
    seachDebounceTimer = setTimeout(() => {
      setSearchParams((prev) => {
        prev.set("page", "1"); // Reset to first page on new search
        prev.set("search", event.target.value || "");
        return prev;
      });
    }, 1000);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSort = (column: string) => {
    const newSortOrder =
      column === sortBy && sortOrder === "ASC" ? "DESC" : "ASC";
    setSearchParams((prev) => {
      prev.set("sortBy", column);
      prev.set("sortOrder", newSortOrder);
      return prev;
    });
  };

  const handleClaimSelect = (claimId: string) => {
    setSelectedClaimId(claimId);
    setSearchParams((prev) => {
      prev.set("claim_h_uuid", claimId);
      return prev;
    });
  };

  const handleCloseModal = () => {
    setSelectedClaimId(null);
    setSearchParams((prev) => {
      prev.delete("claim_h_uuid");
      return prev;
    });
  };

  const handlePageChange = (newPage: number) => {
    setSearchParams((prev) => {
      prev.set("page", newPage.toString());
      return prev;
    });
  };

  const handleTagClick = (tag: string) => {
    setActiveTagFilter((prevTag) => (prevTag === tag ? null : tag));
    setSearchParams((prev) => {
      if (prev.get("tag") === tag) {
        prev.delete("tag");
      } else {
        prev.set("tag", tag);
      }
      prev.set("page", "1"); // Reset to first page when applying a new filter
      return prev;
    });
  };
  const handleAddTag = async (claim_h_uuid: string, tag: string) => {
    let claim = claims.find((c) => c.claim_h_uuid === claim_h_uuid);
    if (!claim) {
      return;
    }
    if (tag.length > 0) {
      claim.tags.push(tag);
      setClaims([...claims]);
      await authFetch("/claim-tags", {
        method: "POST",
        body: JSON.stringify({ claim_h_uuid, tag }),
      }).catch((e) => {
        console.error("Error adding tag:", e);
      });
    }
    setClaims([...claims]);
  };
  const handleDeleteTag = async (claim_h_uuid: string, tag: string) => {
    let claim = claims.find((c) => c.claim_h_uuid === claim_h_uuid);
    if (!claim) {
      return;
    }
    claim.tags = claim.tags.filter((t) => t !== tag);
    setClaims([...claims]);
    await authFetch("/claim-tags", {
      method: "DELETE",
      body: JSON.stringify({ claim_h_uuid, tag }),
    }).catch((e) => {
      console.error("Error deleting tag:", e);
    });
    setClaims([...claims]);
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  const handleFilterChange = (key: string, value: string) => {
    let keyToSet = key;
    setFilters((prev) => ({ ...prev, [keyToSet]: value }));
  };

  const applyFilters = () => {
    setSearchParams((prev) => {
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          prev.set(key, value);
        } else {
          prev.delete(key);
        }
      });
      return prev;
    });
    setIsFilterModalOpen(false);
  };

  const clearFilters = () => {
    setFilters(
      Object.fromEntries(Object.keys(filters).map((key) => [key, ""]))
    );
    setSearchParams(new URLSearchParams());
  };

  const areFiltersApplied = () => {
    // check search params
    let paramsToSearch = Object.fromEntries(searchParams);
    delete paramsToSearch.claim_h_uuid;
    return (
      Object.keys(paramsToSearch).length > 0 ||
      Object.values(filters).some((value) => value !== "")
    );
  };

  const renderFilterInputs = () => {
    return Object.entries(columnConfig).map(([key, config]) => {
      if (!config.filterable || config.hidden) return null;

      const value = filters[key] || "";

      switch (config.filterType) {
        case "text":
        case "number":
        case "date":
          return (
            <FilterGroup key={`${key}-${config.filterType}-${config.title}`}>
              <FilterLabel>{config.title}</FilterLabel>
              <FilterInput
                type={config.filterType}
                value={value}
                onChange={(e) => handleFilterChange(key, e.target.value)}
                placeholder={`Filter ${config.title}`}
              />
            </FilterGroup>
          );
        case "select":
          return (
            <FilterGroup key={`${key}-${config.filterType}-${config.title}`}>
              <FilterLabel>{config.title}</FilterLabel>
              <FilterSelect
                value={value}
                onChange={(e) => handleFilterChange(key, e.target.value)}
              >
                <option value="">All</option>
                {distinctValues[key]?.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </FilterSelect>
            </FilterGroup>
          );
        default:
          return null;
      }
    });
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingBox>
          <LoadingText>Loading Denials</LoadingText>
          <Spinner />
        </LoadingBox>
      </LoadingContainer>
    );
  }

  if (globalError) {
    return <div>Error: {globalError.message}</div>;
  }

  let selectedClaim: CombinedClaim | null | undefined = null;
  if (selectedClaimId) {
    selectedClaim = [
      ...claims,
      ...dailyInsights.map((insight) => insight.claimAnalysis as CombinedClaim),
    ].find((claim) => claim.claim_h_uuid === selectedClaimId);
  }

  const getDashboardScreenContext = (): ScreenStateInterface => {
    let filterParameters: (Parameter | null)[] = Object.entries(columnConfig)
      .map(([key, config]) => {
        if (!config.filterable || config.hidden) return null;
        const value = filters[key] || "";
        switch (config.filterType) {
          case "text":
          case "number":
          case "date":
            return {
              parameterName: key,
              description: `Filter by ${config.title}`,
              type: config.filterType,
              required: false,
            };
          case "select":
            return {
              parameterName: key,
              description: `Filter by ${config.title}`,
              type: config.filterType,
              required: false,
              options: distinctValues[key],
            };
          default:
            return null;
        }
      })
      .filter((x) => x && x !== null);

    let screenContext: any = {
      totalClaims: claims.length,
      activeFilters: filters,
      searchTerm,
    };

    // if (selectedClaim) {
    //   screenContext.selectedClaim = selectedClaim;
    // } else {
    screenContext.visibleClaims = claims.map((claim) => {
      // these are just too big for context
      return {
        ...claim,
        transactions: [],
        notes: [],
      };
    });
    // }

    return {
      screenName: "Manage Denials",
      screenDescription:
        "Claims Dashboard showing list of claims with filters and sorting options",
      screenUrl: window.location.href,
      screenContext,
      screenActions: [
        {
          type: "action",
          actionName: "filterClaims",
          parameters: filterParameters.filter((x) => x !== null) as Parameter[],
        },
        {
          type: "action",
          actionName: "selectClaim",
          parameters: [
            {
              parameterName: "claim_h_uuid",
              description: "Claim H UUID of claim to select",
              type: "string",
              required: true,
            },
          ],
        },
        {
          type: "action",
          actionName: "addBulkTags",
          parameters: [
            {
              parameterName: "tag",
              description: "Tag to add to the claims or new Tag",
              type: "string",
              required: true,
            },
            {
              parameterName: "tagClaimHUUids",
              description: "Comma separated list of claim H UUIDs to tag",
              type: "string",
              required: true,
            },
          ],
        },
        {
          type: "action",
          actionName: "deleteBulkTags",
          parameters: [
            {
              parameterName: "tag",
              description: "Tag to remove from to the claim",
              type: "string",
              required: true,
            },
            {
              parameterName: "tagClaimHUUids",
              description: "Comma separated list of claim H UUIDs to tag",
              type: "string",
              required: true,
            },
          ],
        },
      ],
    };
  };

  const actionSelected = async (action: UIActionInterface) => {
    const userToken = JSON.parse(localStorage.getItem("userToken") || "{}");
    switch (action.actionName) {
      case "addNote": {
        const content = action.parameters.find(
          (p) => p.parameterName === "content"
        )?.selectedValue;
        if (!content) {
          throw new Error("Note content is required");
        }
        if (selectedClaim) {
          setSelectedClaimId(null);
        }

        await authFetch("/claim-notes", {
          method: "POST",
          body: JSON.stringify({
            claim_h_uuid: selectedClaim?.claim_h_uuid,
            user_uuid: userToken.user_uuid,
            content,
          }),
        });

        if (selectedClaim) {
          setSelectedClaimId(selectedClaim.claim_h_uuid);
        }
        // need to refresh the claim somehow to get the new note

        return {
          type: ChatHistoryType.uiActionResult,
          content: `Added note: ${content}`,
          user_facing_messsage: "Note added successfully",
          timestamp: Date.now(),
        };
      }
      case "deleteNote": {
        const id = action.parameters.find(
          (p) => p.parameterName === "id"
        )?.selectedValue;
        if (!id) {
          throw new Error("Note id is required");
        }
        if (selectedClaim) {
          setSelectedClaimId(null);
        }

        await authFetch("/claim-notes", {
          method: "DELETE",
          body: JSON.stringify({ id }),
        });

        if (selectedClaim) {
          setSelectedClaimId(selectedClaim.claim_h_uuid);
        }
        // need to refresh the claim somehow to get the new note

        return {
          type: ChatHistoryType.uiActionResult,
          content: `Deleted Note: ${id}`,
          user_facing_messsage: "Note deleted successfully",
          timestamp: Date.now(),
        };
      }

      case "addBulkTags": {
        const tag = action.parameters.find(
          (p) => p.parameterName === "tag"
        )?.selectedValue;
        if (!tag) {
          throw new Error("Tag is required");
        }
        const tagClaimHUUids = action.parameters.find(
          (p) => p.parameterName === "tagClaimHUUids"
        )?.selectedValue;

        if (!tagClaimHUUids) {
          throw new Error("tagClaimHUUids required");
        }
        let ids: string[] = tagClaimHUUids.split(",");
        await Promise.all(
          ids.map((id) => {
            return handleAddTag(id, tag);
          })
        );
        return {
          type: ChatHistoryType.uiActionResult,
          content: `Added tag: ${tag} to ${ids.length} Claims`,
          user_facing_messsage: "Tag added successfully",
          timestamp: Date.now(),
        };
      }

      case "deleteBulkTags": {
        const tag = action.parameters.find(
          (p) => p.parameterName === "tag"
        )?.selectedValue;
        if (!tag) {
          throw new Error("Tag is required");
        }
        const tagClaimHUUids = action.parameters.find(
          (p) => p.parameterName === "tagClaimHUUids"
        )?.selectedValue;

        if (!tagClaimHUUids) {
          throw new Error("tagClaimHUUids required");
        }
        let ids: string[] = tagClaimHUUids.split(",");
        await Promise.all(
          ids.map((id) => {
            return handleDeleteTag(id, tag);
          })
        );
        return {
          type: ChatHistoryType.uiActionResult,
          content: `Delete tag: ${tag} on ${ids.length} Claims`,
          user_facing_messsage: "Tag removed successfully",
          timestamp: Date.now(),
        };
      }

      case "addTag": {
        const tag = action.parameters.find(
          (p) => p.parameterName === "tag"
        )?.selectedValue;
        if (!tag) {
          throw new Error("Tag is required");
        }

        await handleAddTag(selectedClaim?.claim_h_uuid || "", tag);

        return {
          type: ChatHistoryType.uiActionResult,
          content: `Added tag: ${tag}`,
          user_facing_messsage: "Tag added successfully",
          timestamp: Date.now(),
        };
      }

      case "removeTag": {
        const tag = action.parameters.find(
          (p) => p.parameterName === "tag"
        )?.selectedValue;
        if (!tag) {
          throw new Error("Tag is required");
        }

        await handleDeleteTag(selectedClaim?.claim_h_uuid || "", tag);

        return {
          type: ChatHistoryType.uiActionResult,
          content: `Removed tag: ${tag}`,
          user_facing_messsage: "Tag removed successfully",
          timestamp: Date.now(),
        };
      }

      case "regenerateAnalysis": {
        await authFetch("/resubmission-analysis", {
          method: "POST",
          body: JSON.stringify({ claim_h_uuid: selectedClaim?.claim_h_uuid }),
        });

        return {
          type: ChatHistoryType.uiActionResult,
          content: "Regenerated analysis for claim",
          user_facing_messsage: "Analysis regeneration started",
          timestamp: Date.now(),
        };
      }

      case "answerQuestion": {
        const question = action.parameters.find(
          (p) => p.parameterName === "question"
        )?.selectedValue;
        const answer = action.parameters.find(
          (p) => p.parameterName === "answer"
        )?.selectedValue;

        if (!question || !answer) {
          throw new Error("Question and answer are required");
        }

        await authFetch("/claim-question-and-answers", {
          method: "POST",
          body: JSON.stringify({
            claim_h_uuid: selectedClaim?.claim_h_uuid,
            question,
            answer,
          }),
        });

        return {
          type: ChatHistoryType.uiActionResult,
          content: `Answered question: "${question}" with: "${answer}"`,
          user_facing_messsage: "Answer saved successfully",
          timestamp: Date.now(),
        };
      }

      case "filterClaims":
        setSearchParams((prev) => {
          // clear all previous filters
          for (const key of Object.keys(prev)) {
            prev.delete(key);
          }
          for (const param of action.parameters) {
            if (param.selectedValue !== undefined) {
              prev.set(param.parameterName, param.selectedValue);
            }
          }
          prev.set("page", "1"); // Reset to first page when applying a new filter
          return prev;
        });
        return {
          type: ChatHistoryType.uiActionResult,
          content: `Filtered Claims by Parameters provided ${JSON.stringify(
            action.parameters
          )}`,
          user_facing_messsage: `Filtered Claims.`,
          timestamp: Date.now(),
        };
      case "selectClaim":
        handleClaimSelect(action.parameters[0].selectedValue);
        return {
          type: ChatHistoryType.uiActionResult,
          content: `Selected Claim ${action.parameters[0].selectedValue}`,
          user_facing_messsage: `Selected Claim.`,
          timestamp: Date.now(),
        };
      default:
        break;
    }
    return {
      type: ChatHistoryType.uiActionResult,
      content: `Action ${action.actionName} selected but not run - unknown action`,
      user_facing_messsage: `Action ${action.actionName} selected but not run - unknown action`,
      timestamp: Date.now(),
    };
  };

  const getScreenState = () => {
    let dashboardScreenContext = getDashboardScreenContext();
    if (selectedClaim) {
      if (claimModalChatScreenState) {
        let state = { ...claimModalChatScreenState };
        state.screenActions = [
          ...claimModalChatScreenState.screenActions,
          ...dashboardScreenContext.screenActions,
        ];
        return state;
      }
    }
    return dashboardScreenContext;
  };

  return (
    <Container>
      <Title>Denials</Title>
      {/* {dailyInsights.length > 0 && isDemoTenant() && (
        <InsightsContainer>
          <ToggleButton
            onClick={() => setIsInsightsExpanded(!isInsightsExpanded)}
          >
            {isInsightsExpanded
              ? "Collapse Notable Claims"
              : "Expand Notable Claims"}
          </ToggleButton>
          <CollapsibleContent isexpanded={isInsightsExpanded}>
            <h2>Notable Claims</h2>
            {dailyInsights.map((insight) => (
              <InsightItem
                key={insight.claim_h_uuid}
                onClick={() => handleClaimSelect(insight.claim_h_uuid)}
              >
                <h3>{insight.claimAnalysis.claim_name}</h3>
                <p>
                  <b>Claim Number: </b>
                  {insight.claimAnalysis.claim_number}
                </p>
                <p>{insight.claim_summary}</p>
                <p>{insight.why_its_interesting}</p>
                <p>{insight.why_its_important}</p>
              </InsightItem>
            ))}
          </CollapsibleContent>
        </InsightsContainer>
      )} */}
      <Sidebar isOpen={isSidebarOpen}>
        <FilterModalContent>
          {renderFilterInputs()}
          <ApplyButton onClick={applyFilters}>Apply</ApplyButton>
          <ClearFiltersButton onClick={clearFilters}>
            Clear Filters
          </ClearFiltersButton>
        </FilterModalContent>
      </Sidebar>
      <MainContent isSidebarOpen={isSidebarOpen}>
        <SearchBarContainer>
          <ToggleSidebarButton
            onClick={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
          >
            {isSidebarOpen ? "←" : "→"}
          </ToggleSidebarButton>
          <SearchBar
            type="text"
            placeholder="Search across all fields..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </SearchBarContainer>
        {activeTagFilter && (
          <ActiveFilterIndicator $tagColor={getTagColor(activeTagFilter)}>
            Active Filter: {activeTagFilter}
            <ClearFilterButton onClick={() => handleTagClick(activeTagFilter)}>
              Clear
            </ClearFilterButton>
          </ActiveFilterIndicator>
        )}
        <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                {Object.entries(columnConfig).map(([key, config]) => {
                  let widthofColumn = "150px";
                  if (config.width) {
                    widthofColumn = `${config.width}px`;
                  }
                  return (
                    !config.hidden && (
                      <TableHeaderCell
                        key={key}
                        onClick={() => handleSort(key)}
                        style={{ cursor: "pointer", width: widthofColumn }}
                      >
                        {config.title}
                        {sortBy === key && (sortOrder === "ASC" ? " ▲" : " ▼")}
                      </TableHeaderCell>
                    )
                  );
                })}
              </TableRow>
            </TableHeader>
            <tbody>
              {(claims as any[]).map((claim) => (
                <TableRow
                  key={claim.claim_h_uuid}
                  onClick={() => handleClaimSelect(claim.claim_h_uuid)}
                >
                  {Object.entries(columnConfig).map(([key, config]) => {
                    if (config.hidden) return null;

                    let value = claim[key as keyof CombinedClaim];

                    if (key === "tags") {
                      return (
                        <TableCell key={key}>
                          {(claim.tags || [])
                            .sort((a: string, b: string) => {
                              // sort tags alphabetically
                              // but if the tag is "New" then it should be at the bottom
                              if (a === "New") {
                                return 1;
                              } else if (b === "New") {
                                return -1;
                              } else {
                                return a.localeCompare(b);
                              }
                            })
                            .map((tag: string) => (
                              <TagButton
                                key={tag}
                                $tagColor={getTagColor(tag)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleTagClick(tag);
                                }}
                              >
                                {tag}
                              </TagButton>
                            ))}
                        </TableCell>
                      );
                    }

                    if ((value || "").length > 100) {
                      value = value.substring(0, 97) + "...";
                    }
                    return config.truncate ? (
                      <TruncatedCell key={key} title={String(claim[key])}>
                        {String(value)}
                      </TruncatedCell>
                    ) : (
                      <TableCell key={key}>
                        {config.formatAmount && typeof claim[key] === "number"
                          ? `$${claim[key].toFixed(2)}`
                          : config.formatDate
                          ? new Date(claim[key]).toLocaleDateString()
                          : String(claim[key])}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        {totalPages > 1 && (
          <PaginationContainer>
            <PageButton
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              Previous
            </PageButton>
            <span>
              Page {page} of {totalPages}
            </span>
            <PageButton
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            >
              Next
            </PageButton>
          </PaginationContainer>
        )}
        {selectedClaim && (
          <ClaimModal
            claim={selectedClaim}
            handleAddTag={handleAddTag}
            handleDeleteTag={handleDeleteTag}
            onClose={handleCloseModal}
            setChatScreenState={(screenState: ScreenStateInterface) => {
              setClaimModalChatScreenState(screenState);
            }}
          />
        )}
      </MainContent>
      <ChatBot
        screenState={getScreenState()}
        onActionSelected={actionSelected}
      />
    </Container>
  );
};

export default DashboardPage;
