// components/ServicePayments.tsx
import React from "react";
import styled from "styled-components";
import { ServicePaymentsProps } from "../Constants/interfaces";

const ServicePaymentsContainer = styled.div`
  // Remove all container styling since it's wrapped in DropdownContent
`;

const ServicePayment = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  width: 100%;
`;

const HeaderLabel = styled.label`
  font-weight: bold;
  margin-right: 10px;
  font-size: 14px;
`;

const PaymentDetail = styled.div`
`;

const ServicePayments: React.FC<ServicePaymentsProps> = ({ claim }) => {
  if (!claim) {
    return (
      <ServicePaymentsContainer>
        Select a claim to view service information
      </ServicePaymentsContainer>
    );
  }

  return (
    <ServicePaymentsContainer>
      <ServicePayment>
        <InfoContainer>
          <h4>
            {claim.code} - {claim.code_explanation}
          </h4>
          <PaymentDetail>
            {claim.procedure_explanation}
          </PaymentDetail>
          {/* <PaymentDetail>
            <HeaderLabel>Charge Amount:</HeaderLabel>
            <span>${claim.charge_amount.toFixed(2)}</span>
          </PaymentDetail>
          <PaymentDetail>
            <HeaderLabel>Allowed Amount:</HeaderLabel>
            <span>${claim.allowed_amount.toFixed(2)}</span>
          </PaymentDetail>
          <PaymentDetail>
            <HeaderLabel>Paid Amount:</HeaderLabel>
            <span>${claim.paid_amount.toFixed(2)}</span>
          </PaymentDetail> */}
        </InfoContainer>
      </ServicePayment>
    </ServicePaymentsContainer>
  );
};

export default ServicePayments;
