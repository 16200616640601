// components/Claim835s.tsx
import React, { useState } from "react";
import styled from "styled-components";
import { statusTags } from "../Constants/interfaces";

const Claim835sContainer = styled.div`
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background: white;
  display: flex;
  gap: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  margin-bottom: 20px;
`;

const InfoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  
  h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
`;

const InfoRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: baseline;
`;

const HeaderLabel = styled.label`
  font-weight: 600;
  margin-right: 10px;
  min-width: 140px;
  color: #555;
`;

const TagSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 250px;
  flex-shrink: 0;
  margin-right: 20px;
  margin-top: 40px;
  position: relative;
  height: fit-content;
  padding-bottom: 60px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-start;
  max-width: 100%;
  min-height: 30px;
  width: 100%;
`;

const Tag = styled.span`
  background-color: #e0e0e0;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  text-align: left;
`;

const RemoveTagButton = styled.button`
  background: none;
  border: none;
  color: #888;
  margin-left: 5px;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 1;
`;

const AddTagForm = styled.form`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
`;

const AddTagInput = styled.input`
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
  min-width: 100px;
  text-align: left;
`;

const AddTagButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
`;
const StatusTagDropdown = styled.select`
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
`;

const RegenerateButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f39c12;
  color: #fff;
  transition: background-color 0.2s ease;
  width: 100%;
  position: absolute;
  bottom: 0;
  
  &:hover {
    background-color: #e67e22;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

interface ClaimDetailProps {
  claim: any;
  onTagAdd: (tag: string) => void;
  onTagRemove: (tag: string) => void;
  onRegenerate: () => void;
  isLoading: boolean;
}

const ClaimDetails: React.FC<ClaimDetailProps> = ({
  claim,
  onTagAdd,
  onTagRemove,
  onRegenerate,
  isLoading
}) => {
  const [newTag, setNewTag] = useState("");

  if (!claim) {
    return (
      <Claim835sContainer>Select a claim to view details</Claim835sContainer>
    );
  }

  const handleAddTag = (e: React.FormEvent) => {
    if (!claim) return;
    e.preventDefault();
    if (newTag.trim()) {
      onTagAdd(newTag.trim());
      setNewTag("");
    }
  };
  const handleTagRemove = (tag: string) => {
    onTagRemove(tag);
    console.log("Removed Tag", tag);
    // setNewTag(tag); // force ui update
  };

  const handleStatusTagChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!claim) return;
    const newStatus = e.target.value;
    // see if the claim already has a status tag
    try {
      // Remove old status tags

      for (let t = 0; t < statusTags.length; t++) {
        const tag = statusTags[t];
        if (claim?.tags.includes(tag)) {
          onTagRemove(tag);
        }
      }

      // Add new status tag
      onTagAdd(newStatus);
    } catch (error) {
      console.error("Error updating claim status:", error);
    }
  };

  const statusTag = claim.tags.find((tag: string) =>
    ["No Action Needed", "Needs Action", "Actioned", "Archived"].includes(tag)
  );

  return (
    <Claim835sContainer>
      <InfoRowContainer>
        <InfoRow>
          <h2>{claim.claim_name}</h2>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Claim Number:</HeaderLabel>
          <span>{claim.claim_number}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Patient Name:</HeaderLabel>
          <span>{claim.patient_name}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Status:</HeaderLabel>
          <span>{claim.claim_sub_status}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Payer:</HeaderLabel>
          <span>{claim.payer_name}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Total Claim Amount:</HeaderLabel>
          <span>${claim.charge_amount.toFixed(2)}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Allowed Amount:</HeaderLabel>
          <span>${claim.allowed_amount.toFixed(2)}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Paid Amount:</HeaderLabel>
          <span>${claim.paid_amount.toFixed(2)}</span>
        </InfoRow>

        <InfoRow>
          <HeaderLabel>Date Of Service:</HeaderLabel>
          <span>{new Date(claim.procedure_date).toLocaleDateString()}</span>
        </InfoRow>
        {/* <InfoRow>
          <HeaderLabel>Payer:</HeaderLabel>
          <span>{claim.payer}</span>
        </InfoRow> */}
        <InfoRow>
          <HeaderLabel>Insurance Type:</HeaderLabel>
          <span>{claim.insurance_type}</span>
        </InfoRow>
      </InfoRowContainer>
      
      <TagSection>
        <StatusTagDropdown value={statusTag} onChange={handleStatusTagChange}>
          <option value="">Select Status Tag</option>
          <option value="No Action Needed">No Action Needed</option>
          <option value="Needs Action">Needs Action</option>
          <option value="Actioned">Actioned</option>
          <option value="Archived">Archived</option>
        </StatusTagDropdown>
        
        <TagContainer>
          {(claim.tags || [])
            .filter((t: string) => !statusTags.includes(t))
            .map((tag: string) => (
              <Tag key={tag}>
                {tag}
                <RemoveTagButton
                  onClick={() => handleTagRemove(tag)}
                >
                  ×
                </RemoveTagButton>
              </Tag>
            ))}
        </TagContainer>
        
        <AddTagForm onSubmit={handleAddTag}>
          <AddTagInput
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            placeholder="Add a new tag"
          />
          <AddTagButton type="submit">Add Tag</AddTagButton>
        </AddTagForm>

        <RegenerateButton
          onClick={onRegenerate}
          disabled={isLoading}
        >
          {isLoading ? "Finding Solutions..." : "Regenerate Solution"}
        </RegenerateButton>
      </TagSection>
    </Claim835sContainer>
  );
};

export default ClaimDetails;
