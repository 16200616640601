import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { authFetch } from "../Utils/authFetch";
import {
  CombinedClaim,
  QuestionAndAnswer,
  ResubmissionAnalysis,
} from "../Constants/interfaces";

const Container = styled.div`
  // Remove duplicate styling since it's now wrapped in DropdownContent
`;

const Question = styled.div`
  margin-bottom: 15px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const QuestionText = styled.p`
  font-weight: bold;
`;

const AnswerInput = styled.input`
  width: 50%;
  margin-top: 5px;
`;

const AnswerSelect = styled.select`
  width: 50%;
  margin-top: 5px;
`;

const SaveButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }
`;
interface QuestionAndAnswersProps {
  claim: CombinedClaim;
  resubmissionAnalysis: ResubmissionAnalysis | null;
}

const QuestionAndAnswers: React.FC<QuestionAndAnswersProps> = ({
  claim,
  resubmissionAnalysis,
}) => {
  const [answers, setAnswers] = useState<QuestionAndAnswer[]>([]);
  const [newAnswers, setNewAnswers] = useState<Record<string, string>>({});

  const fetchAnswers = async () => {
    try {
      const data = await authFetch(
        `/claim-question-and-answers?claim_h_uuid=${claim.claim_h_uuid}`
      );
      setAnswers(data);
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };

  useEffect(() => {
    fetchAnswers();
  }, [claim.claim_h_uuid]);

  const handleAnswerChange = (question: string, answer: string) => {
    setNewAnswers((prev) => ({ ...prev, [question]: answer }));
  };

  const saveAnswers = async () => {
    let entries = Object.entries(newAnswers);
    for (let i = 0; i < entries.length; i++) {
      const [question, answer] = entries[i];
      try {
        await authFetch("/claim-question-and-answers", {
          method: "POST",
          body: JSON.stringify({
            claim_h_uuid: claim.claim_h_uuid,
            question,
            answer,
          }),
        });
      } catch (error) {
        console.error("Error saving answer:", error);
      }
    }
    fetchAnswers();
    setNewAnswers({});
  };

  const renderQuestion = (
    question: string,
    type?: string,
    options?: string[]
  ) => {
    const existingAnswer = answers.find((a) => a.question === question);
    return (
      <Question key={question}>
        <QuestionText>{question}</QuestionText>
        {type === "select" && options ? (
          <AnswerSelect
            value={newAnswers[question] || existingAnswer?.answer || ""}
            onChange={(e) => handleAnswerChange(question, e.target.value)}
          >
            <option value="">Select an answer</option>
            {options.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </AnswerSelect>
        ) : (
          <AnswerInput
            type="text"
            value={newAnswers[question] || existingAnswer?.answer || ""}
            onChange={(e) => handleAnswerChange(question, e.target.value)}
            placeholder="Enter your answer"
          />
        )}
      </Question>
    );
  };

  if (
    (answers || []).length == 0 &&
    (resubmissionAnalysis?.additional_info_needed || []).length == 0
  ) {
    return null;
  }

  return (
    resubmissionAnalysis && (
      <Container>
        {answers.length > 0 && (
          <>
            <h3>Answered Questions:</h3>
            {answers.map((item) => renderQuestion(item.question))}
          </>
        )}

        {resubmissionAnalysis.additional_info_needed?.length > 0 && (
          <>
            <h3>Questions to Answer:</h3>
            {resubmissionAnalysis.additional_info_needed.map((item) =>
              renderQuestion(item.question, item.type, item.options)
            )}
          </>
        )}

        {Object.keys(newAnswers).length > 0 && (
          <SaveButton onClick={saveAnswers}>Save Answers</SaveButton>
        )}
      </Container>
    )
  );
};

export default QuestionAndAnswers;
