// components/Associated835s.tsx
import React from "react";
import styled from "styled-components";
import { Transaction } from "../Constants/interfaces";

const Associated835sContainer = styled.div`
  background: #f8f8f8;
  padding: 15px;
  border-radius: 4px;
  width: calc(100% - 40px);
  margin: 0 auto;
  overflow-x: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0;
`;

const Th = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  word-wrap: break-word;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  vertical-align: top;
  word-wrap: break-word;
  
  & > div {
    word-break: break-word;
  }
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f1f1f1;
  }
`;

const SegmentTable = styled(Table)`
  margin: 0;
  width: 100%;
  
  ${Th} {
    width: 33.33%;
  }
`;

interface Associated835sProps {
  transactions: Transaction[];
}

const Associated835s: React.FC<Associated835sProps> = ({ transactions }) => {
  return (
    <Associated835sContainer>
      <Table>
        <thead>
          <tr>
            <Th style={{ width: '20%' }}>Transaction</Th>
            <Th style={{ width: '80%' }}>Segments</Th>
          </tr>
        </thead>
        <tbody>
          {(transactions || []).map((trans, index) => (
            <Tr key={`${index}-sub`}>
              <Td>
                <h2 style={{ margin: '0 0 5px 0', fontSize: '16px' }}>{trans.transaction_type}</h2>
                <h3 style={{ margin: 0, fontSize: '14px', fontWeight: 'normal' }}>
                  {new Date(trans.transaction_date || "").toLocaleDateString()}
                </h3>
              </Td>
              <Td>
                <SegmentTable>
                  <thead>
                    <Tr>
                      <Th>Segment Id</Th>
                      <Th>Segment Name</Th>
                      <Th>Segment Data</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {(trans.segments || []).map((segment, idx) => (
                      <Tr key={idx}>
                        <Td>{segment.segment_id}</Td>
                        <Td>{segment.segment_name}</Td>
                        <Td>
                          {Object.keys(segment.data || [])
                            .filter((k) => !(k || "").endsWith("_phi_key"))
                            .map((key) => {
                              let value = segment.data[key];
                              if (Array.isArray(value)) {
                                let aValues = value.map((v) => {
                                  if (typeof v === "object" || Array.isArray(v)) {
                                    return JSON.stringify(v);
                                  }
                                  return v;
                                });
                                value = aValues.join("\n\n");
                              }
                              if (typeof value === "object") {
                                let objectVal = value as any;
                                let kvPairs = Object.keys(objectVal).map((k) => {
                                  if (
                                    typeof objectVal[k] === "object" ||
                                    Array.isArray(objectVal[k])
                                  ) {
                                    return `${k}: ${JSON.stringify(objectVal[k])}`;
                                  }
                                  return `${k}: ${objectVal[k]}`;
                                });
                                value = kvPairs.join(`\n\n`);
                              }
                              return (
                                <div key={key}>
                                  {key} : {`${value}`}
                                </div>
                              );
                            })}
                        </Td>
                      </Tr>
                    ))}
                  </tbody>
                </SegmentTable>
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Associated835sContainer>
  );
};

export default Associated835s;
