// components/ClaimModal.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import Analysis from "./Analysis";
import ServicePayments from "./ServicePayments";
import Notes from "./Notes";
import { ClaimModalProps, CombinedClaim } from "../Constants/interfaces";
import ClaimDetails from "./ClaimDetails";
import ResubmissionAnalysisComponent from "./ResubmissionAnalysis";
import { authFetch } from "../Utils/authFetch";
import Associated835s from "./Associated835s";
import QuestionAndAnswers from "./QuestionAndAnswers";
import { showAlert } from "../Utils/alerts";

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const ContentSection = styled.div`
  flex: 1;
  padding-bottom: 20px;
`;

const DropdownSection = styled.div`
  margin-top: 20px;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
`;

const DropdownHeader = styled.div`
  cursor: pointer;
  padding: 15px 20px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  span {
    margin-right: 10px;
  }
`;

const DropdownContent = styled.div`
  border: 1px solid #ddd;
  padding: 15px 20px;
  border-radius: 5px;
  background: #f9f9f9;
  margin: 0 20px;
`;

const NotesSection = styled(DropdownContent)`
  margin-top: 10px;
  min-height: 100px;
  resize: vertical;
  overflow-y: auto;
`;

const ClaimModal: React.FC<ClaimModalProps> = ({
  claim,
  handleAddTag,
  handleDeleteTag,
  onClose,
  setChatScreenState,
}) => {
  const [resubmissionAnalysis, setResubmissionAnalysis] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isQuestionsOpen, setIsQuestionsOpen] = useState(true);
  const [isSummaryOpen, setIsSummaryOpen] = useState(true);
  const [isSolutionOpen, setIsSolutionOpen] = useState(true);
  const [notes, setNotes] = useState("");

  const fetchResubmissionAnalysis = async () => {
    setIsLoading(true);
    try {
      const response = await authFetch(
        `/resubmission-analysis?claim_h_uuid=${claim.claim_h_uuid}`
      );
      let analysisData = response.analysis_data;
      // check if string and parse

      if (typeof analysisData === "string") {
        analysisData = JSON.parse(analysisData);
      }

      setResubmissionAnalysis(analysisData);
    } catch (error) {
      console.error("Error fetching resubmission analysis:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchResubmissionAnalysis();
  }, [claim.claim_h_uuid]);

  const handleRegenerateAnalysis = async () => {
    setIsLoading(true);
    try {
      // I'm worried this might timeout
      setResubmissionAnalysis(null);
      let result = await authFetch("/resubmission-analysis", {
        method: "POST",
        body: JSON.stringify({ claim_h_uuid: claim.claim_h_uuid }),
      });
      setResubmissionAnalysis(resubmissionAnalysis.analysis_data);
      fetchResubmissionAnalysis();
    } catch (error) {
      console.error("Error regenerating resubmission analysis:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getClaimModalScreenState = () => {
    return {
      screenName: "Claim Details",
      screenDescription: `Viewing details for claim ${claim.claim_name}`,
      screenUrl: window.location.href,
      screenContext: {
        claim,
        resubmissionAnalysis,
      },
      screenActions: [
        {
          type: "action",
          actionName: "addNote",
          parameters: [
            {
              parameterName: "content",
              description: "Content of the note to add",
              type: "string",
              required: true,
            },
          ],
        },
        {
          type: "action",
          actionName: "deleteNote",
          parameters: [
            {
              parameterName: "id",
              description: "id of the note to delete",
              type: "string",
              required: true,
            },
          ],
        },
        {
          type: "action",
          actionName: "addTag",
          parameters: [
            {
              parameterName: "tag",
              description: "Tag to add to the claim (or new tag)",
              type: "string",
              required: true,
            },
          ],
        },
        {
          type: "action",
          actionName: "removeTag",
          parameters: [
            {
              parameterName: "tag",
              description: "Tag to remove from the claim",
              type: "string",
              required: true,
              options: claim.tags,
            },
          ],
        },
        {
          type: "action",
          actionName: "regenerateAnalysis",
          parameters: [],
        },
        {
          type: "action",
          actionName: "answerQuestion",
          parameters: [
            {
              parameterName: "question",
              description: "Question to answer",
              type: "string",
              required: true,
              options:
                resubmissionAnalysis?.additional_info_needed?.map(
                  (q: { question: any }) => q.question || ""
                ) || [],
            },
            {
              parameterName: "answer",
              description: "Answer to the question",
              type: "string",
              required: true,
            },
          ],
        },
      ],
    };
  };

  useEffect(() => {
    // Update the screen state when the modal is opened
    const screenState = getClaimModalScreenState();
    setChatScreenState(screenState);
  }, [claim, resubmissionAnalysis, claim.notes]);

  if (!claim) {
    return null;
  }

  let open = true;

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalContent>
        <ContentSection>
          <ClaimDetails
            claim={claim}
            onTagAdd={(tag) => handleAddTag(claim.claim_h_uuid, tag)}
            onTagRemove={(tag) => handleDeleteTag(claim.claim_h_uuid, tag)}
            onRegenerate={handleRegenerateAnalysis}
            isLoading={isLoading}
          />
          
          <DropdownSection>
            <DropdownHeader 
              onClick={() => setIsSummaryOpen(!isSummaryOpen)} 
              className="heading"
            >
              <span>Summary</span> {isSummaryOpen ? '▼' : '▶'}
            </DropdownHeader>
            {isSummaryOpen && (
              <DropdownContent>
                <ServicePayments claim={claim} />
                <Analysis claim={claim} />
              </DropdownContent>
            )}
          </DropdownSection>

          <DropdownSection>
            <DropdownHeader 
              onClick={() => setIsSolutionOpen(!isSolutionOpen)} 
              className="heading"
            >
              <span>Solution Recommendation</span> {isSolutionOpen ? '▼' : '▶'}
            </DropdownHeader>
            {isSolutionOpen && (
              <DropdownContent>
                <ResubmissionAnalysisComponent
                  analysis={resubmissionAnalysis}
                  isLoading={isLoading}
                  handleRegenerateAnalysis={handleRegenerateAnalysis}
                />
              </DropdownContent>
            )}
          </DropdownSection>

          <DropdownSection>
            <DropdownHeader 
              onClick={() => setIsNotesOpen(!isNotesOpen)} 
              className="heading"
            >
              <span>Additional Notes and Documentation</span> {isNotesOpen ? '▼' : '▶'}
            </DropdownHeader>
            {isNotesOpen && (
              <NotesSection>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Add notes here..."
                  style={{
                    width: '100%',
                    border: 'none',
                    background: 'transparent',
                    resize: 'vertical',
                    minHeight: '100px',
                    outline: 'none',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                  }}
                />
              </NotesSection>
            )}
          </DropdownSection>

          <DropdownSection>
            <DropdownHeader 
              onClick={() => setIsQuestionsOpen(!isQuestionsOpen)} 
              className="heading"
            >
              <span>Questions and Answers</span> {isQuestionsOpen ? '▼' : '▶'}
            </DropdownHeader>
            {isQuestionsOpen && (
              <DropdownContent>
                <QuestionAndAnswers
                  claim={claim}
                  resubmissionAnalysis={resubmissionAnalysis}
                />
              </DropdownContent>
            )}
          </DropdownSection>

          <DropdownSection>
            <DropdownHeader 
              onClick={() => setIsTransactionsOpen(!isTransactionsOpen)}
              className="heading"
            >
              <span>Transactions</span> {isTransactionsOpen ? '▼' : '▶'}
            </DropdownHeader>
            {isTransactionsOpen && (
              <Associated835s transactions={claim.transactions || []} />
            )}
          </DropdownSection>
        </ContentSection>
      </ModalContent>
    </Modal>
  );
};

export default ClaimModal;
