// components/Analysis.tsx
import React from "react";
import styled from "styled-components";
import { AnalysisProps } from "../Constants/interfaces";
import ReactMarkdown from "react-markdown";

const AnalysisContainer = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ddd;
`;

const Explanation = styled.div`
  background-color: #f8f8f8;
  border-radius: 5px;
`;

const Analysis: React.FC<AnalysisProps> = ({ claim }) => {
  if (!claim) {
    return (
      <AnalysisContainer>Select a claim to view analysis</AnalysisContainer>
    );
  }

  // the claim.highbound_analysis might be a JSON string of an array or
  // something we want to comma separate or display in markdown
  let analysis = claim.highbound_analysis;
  if (typeof analysis === "string") {
    try {
      let analysisArray = JSON.parse(analysis);
      if (Array.isArray(analysisArray)) {
        analysis = analysisArray.join(`\n\n`);
      }
    } catch (error) {
      analysis = claim.highbound_analysis;
    }
  }
  return (
    <AnalysisContainer>
      <h4>Summary</h4>
      <Explanation>
        <p>{claim.claim_status_summary}</p>
      </Explanation>
      <Explanation>
        <h4>Detailed Analysis</h4>
        <ReactMarkdown>{analysis}</ReactMarkdown>
      </Explanation>
      {/* <Steps>
        <h4>Recommended Corrective Actions</h4>
        <Step>{claim.resubmission_next_steps}</Step>
      </Steps> */}
    </AnalysisContainer>
  );
};

export default Analysis;
