// components/ResubmissionAnalysis.tsx
import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { FiDownload, FiMail, FiLoader } from 'react-icons/fi';

const AnalysisContainer = styled.div`
  background: #f9f9f9;
`;

const SectionContainer = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin: 20px 0;
  background: #fff;
`;

const SectionHeader = styled.div`
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SectionContent = styled.div`
  padding: 15px;
  border-radius: 4px;
`;

const NextStepsList = styled.ol`
  margin: 0;
  padding-left: 20px;
`;

const NextStepItem = styled.li`
  margin-bottom: 10px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const EmailField = styled.div`
  margin-bottom: 5px;
  display: flex;
  gap: 10px;
  
  strong {
    min-width: 60px;
  }
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  transition: background-color 0.2s ease;
  font-size: 16px;
  width: 50%;
  &:hover {
    background-color: #218838;
  }
`;

const RegenerateButton = styled(StyledButton)`
  background-color: #f39c12;
  &:hover {
    background-color: #e67e22;
  }
  margin-top: 20px;
`;

const CitationItem = styled.div`
  margin-top: 0;
  margin-bottom: 15px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const CitationSource = styled.h4`
  margin: 0 0 5px 0;
  font-size: 16px;
`;

const CitationDetails = styled.div`
  color: #666;
  font-size: 14px;
  margin-bottom: 3px;
`;

const Form837Content = styled.pre`
  padding: 15px;
  border-radius: 4px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  color: #666;
  font-size: 20px;
  
  &:hover {
    color: #000;
  }
`;

const EmailContent = styled.div`
  padding: 15px;
  border-radius: 4px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
`;

const SpinningLoader = styled(FiLoader)`
  animation: spin 2s linear infinite;
  font-size: 40px;
  color: #0078d4;
  margin-bottom: 15px;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

const LoadingText = styled.div`
  font-size: 16px;
  color: #666;
  margin-top: 10px;
`;

const LoadingSubText = styled.div`
  font-size: 14px;
  color: #999;
  margin-top: 5px;
`;

const MarkdownContent = styled(ReactMarkdown)`
  margin-top: 0;
  
  & > *:first-child {
    margin-top: 0;
  }
`;

interface ResubmissionAnalysisProps {
  analysis: any;
  isLoading: boolean;
  handleRegenerateAnalysis: () => void;
}

const ResubmissionAnalysis: React.FC<ResubmissionAnalysisProps> = ({
  analysis,
  isLoading,
  handleRegenerateAnalysis,
}) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <SpinningLoader />
        <LoadingText>Analyzing Claim Data</LoadingText>
        <LoadingSubText>Generating resubmission recommendations...</LoadingSubText>
      </LoadingContainer>
    );
  }

  if (!analysis) {
    return null;
  }

  const handleOpenEmail = () => {
    console.log("Opening email");
    const subject = "Claim Resubmission Request";
    let body = analysis?.resubmission_letter || "";
    
    // Remove markdown formatting and format lists
    body = body
      .replace(/^#+ /gm, '')  // Remove heading markers
      .replace(/\*\*/g, '')   // Remove bold markers
      .replace(/\*/g, '')     // Remove italic markers
      .replace(/^- /gm, '• ') // Replace markdown lists with bullet points
      .replace(/^([0-9]+)\. /gm, '$1) ') // Convert numbered lists to 1) 2) format
      .replace(/^([0-9]+)\)/gm, '\n$1)')
      .replace(/\n{3,}/g, '\n\n')
      .replace(/\n\n([A-Z][a-z]+ [A-Z][a-z]+:)/g, '\n\n\n$1')
      .replace(/([.:])\n([A-Z])/g, '$1\n\n$2');
      
    // Format for email
    const formattedBody = encodeURIComponent(
      body
        .replace(/\n/g, '\r\n')
        .replace(/\r\r/g, '\r')
        .trim()
    );
    
    const mailtoLink = `mailto:subject=${encodeURIComponent(subject)}&body=${formattedBody}`;
    
    console.log("Mailto link:", mailtoLink);
    
    // Create and click a temporary link
    const tempLink = document.createElement('a');
    tempLink.href = mailtoLink;
    tempLink.style.display = 'none';
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const handleDownloadJson = () => {
    const jsonString = JSON.stringify(analysis.form_837_suggestion, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `form_837_${analysis.claim_id || 'resubmission'}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <AnalysisContainer>
      <SectionContainer>
        <SectionHeader>
          <HeaderTitle>Approval Analysis</HeaderTitle>
        </SectionHeader>
        <SectionContent>
          <MarkdownContent>{analysis.approval_analysis}</MarkdownContent>
        </SectionContent>
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>
          <HeaderTitle>Suggested Next Steps</HeaderTitle>
        </SectionHeader>
        <SectionContent>
          <NextStepsList>
            {(analysis?.next_steps || []).map((step: any, index: number) => (
              <NextStepItem key={index}>
                <strong>{step.step}</strong>: {step.description}
              </NextStepItem>
            ))}
          </NextStepsList>
        </SectionContent>
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>
          <HeaderTitle>
            Resubmission Email Draft
            <IconButton 
              onClick={handleOpenEmail}
              title="Open in Email Editor"
            >
              <FiMail />
            </IconButton>
          </HeaderTitle>
        </SectionHeader>
        <EmailContent>
          <EmailField>
            <strong>To:</strong>
            <span>[Recipient Email]</span>
          </EmailField>
          <EmailField>
            <strong>Subject:</strong>
            <span>Claim Resubmission Request</span>
          </EmailField>
          <ReactMarkdown>{analysis?.resubmission_letter || ""}</ReactMarkdown>
        </EmailContent>
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>
          <HeaderTitle>
            Resubmission Form 837
            <IconButton 
              onClick={handleDownloadJson}
              title="Download JSON"
            >
              <FiDownload />
            </IconButton>
          </HeaderTitle>
        </SectionHeader>
        <Form837Content>
          {JSON.stringify(analysis.form_837_suggestion, null, 2)}
        </Form837Content>
      </SectionContainer>

      {(analysis?.citations || []).length > 0 && (
        <SectionContainer>
          <SectionHeader>
            <HeaderTitle>Sources</HeaderTitle>
          </SectionHeader>
          <SectionContent>
            {(analysis?.citations || []).map((citation: any, index: number) => (
              <CitationItem key={index}>
                <CitationSource>{citation.source}</CitationSource>
                <CitationDetails>
                  {citation.fileName && `File: ${citation.fileName}`}
                  {citation.pageNumber && ` | Page: ${citation.pageNumber}`}
                </CitationDetails>
                <CitationDetails>Relevance: {citation.relevance}</CitationDetails>
              </CitationItem>
            ))}
          </SectionContent>
        </SectionContainer>
      )}
      
      {/* <RegenerateButton
        onClick={handleRegenerateAnalysis}
        disabled={isLoading}
      >
        {isLoading ? "Finding Solutions..." : "Regenerate Solution"}
      </RegenerateButton> */}
    </AnalysisContainer>
  );
};

export default ResubmissionAnalysis;
